import React from 'react';
import styled from 'react-emotion';

import theme from '@jetshop/ui/utils/theme';

export const ColumnWrapper = styled('div')`
  &.maxWidth {
    margin: auto;
    width: 100%;
    min-width: 0;
    max-width: ${props => props.maxWidth};
    ${theme('below.lg')} {
      h4 {
        margin-top: 0;
      }
    }
  }

  ${theme('below.lg')} {
    h4 {
      margin-top: 0;
      padding-left: 0;
    }
  }
`;

const Column = ({ children, maxWidth }) => (
  <ColumnWrapper
    className={maxWidth ? 'maxWidth' : null}
    maxWidth={maxWidth ? maxWidth : null}
  >
    {children}
  </ColumnWrapper>
);

export default Column;
