import React from 'react';
import styled from 'react-emotion';

import theme from '@jetshop/ui/utils/theme';

const QuoteWrapper = styled('div')`
  margin-bottom: 40px;
  position: relative;
  text-align: center;
  width: 100%;
  display: block;

  ${theme('below.md')} {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  h3 {
    font-size: 1.5rem;
    color: ${theme('colors.primary')};
    line-height: 1.4;
    max-width: 600px;
    text-align: center;
    text-transform: none;
    padding: 0;
    margin: auto;
    ${theme('below.md')} {
      font-size: 16px;
      padding: 0 10px 0 10px;
    }
  }
  span {
    display: block;
    margin-top: 15px;
    font-size: 14px;
    font-weight: bold;
    font-family: ${theme('fonts.secondary')};
  }
`;

const Quote = ({ children, name }) => (
  <QuoteWrapper>
    <h3>{children}</h3>
    {name ? <span>{name}</span> : null}
  </QuoteWrapper>
);

export default Quote;
