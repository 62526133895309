import React from 'react';
import styled from 'react-emotion';

export const VideoWrapper = styled('div')`
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
  margin-bottom: 10px;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
  }
`;

const Column = ({ url }) => {
  if (url.indexOf('?v=') > -1) {
    url = url.split('?v=')[1];
    url =
      'https://www.youtube.com/embed/' + url + '?rel=0&loop=1&playlist=' + url;
  }
  return (
    <VideoWrapper>
      <iframe
        title={url}
        width="100%"
        height="auto"
        src={url}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </VideoWrapper>
  );
};

export default Column;
