import React from 'react';
import styled from 'react-emotion';

import theme from '@jetshop/ui/utils/theme';
import { Link } from 'react-router-dom';

const PageLinkWrapper = styled(Link)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  border: 1px solid #cacaca;
  height: 100px;
  width: 100%;
  text-decoration: none;
  &:hover {
    border-color: black;
  }

  img {
    display: block;
    max-width: 30px !important;
    margin-bottom: 5px;
  }

  h2,
  .pagelink-title {
    font-size: 14px;
    text-transform: none;
    text-decoration: none;
    margin: 0;
    text-align: center;
    line-height: 1.2;
  }

  ${theme('below.lg')} {
    width: 100%;
  }
`;
const ExternalPageLinkWrapper = styled('a')`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  border: 1px solid #cacaca;
  height: 100px;
  width: 100%;
  text-decoration: none;
  &:hover {
    border-color: black;
  }

  img {
    display: block;
    max-width: 30px !important;
    margin-bottom: 5px;
  }

  h2,
  .pagelink-title {
    font-size: 14px;
    text-transform: none;
    text-decoration: none;
    margin: 0;
    text-align: center;
    line-height: 1.2;
  }

  ${theme('below.lg')} {
    width: 100%;
  }
`;
const PageLink = ({ link, icon, text, blank }) => (
  <React.Fragment>
    {blank ? (
      <ExternalPageLinkWrapper href={link} target="_blank">
        {icon ? <img src={icon} alt={text} /> : null}
        <h2 className="pagelink-title">{text}</h2>
      </ExternalPageLinkWrapper>
    ) : (
      <PageLinkWrapper to={link}>
        {icon ? <img src={icon} alt={text} /> : null}
        <h2 className="pagelink-title">{text}</h2>
      </PageLinkWrapper>
    )}
  </React.Fragment>
);

export default PageLink;
