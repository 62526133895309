import React from 'react';
import styled from 'react-emotion';
import Image from '@jetshop/ui/Image';
import theme from '@jetshop/ui/utils/theme';

const LongTextImageWrapper = styled('div')`
  display: flex;
  margin-bottom: 2rem;
  ${theme('below.lg')} {
    flex-wrap: wrap;
  }

  &.right {
    flex-direction: row-reverse;
  }
  &.left {
    flex-direction: row;
  }
`;
const Column = styled('div')`
  width: 50%;
  ${theme('below.lg')} {
    width: 100%;
  }
`;

const LongTextImage = ({ image, children, imagePosition }) => (
  <LongTextImageWrapper className={imagePosition ? imagePosition : 'right'}>
    <Column>{children}</Column>
    <Column>
      <Image src={image} aspect={'1:1'} cover={true} />
    </Column>
  </LongTextImageWrapper>
);

export default LongTextImage;
