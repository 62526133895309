import React from 'react';
import styled from 'react-emotion';
import { Query } from 'react-apollo';

import theme from '@jetshop/ui/utils/theme';

import ProductGrid from '../CategoryPage//ProductGrid';
import { ProductCard } from '../CategoryPage/ProductCard';
import productRowQuery from './productRowQuery.gql';

const ProductGridWrapper = styled('div')`
  max-width: 1200px;
  padding: 10px;
  margin: 0 auto 30px auto;
  width: 100%;
  h2 {
    text-align: center;
    text-transform: uppercase;
    font-size: 25px;
    margin-bottom: 20px;
    font-weight: bold;
  }
  ${theme('below.lg')} {
    max-width: 100%;
    padding: 20px 0;
  }

  li {
    ${theme('above.lg')} {
      width: 20% !important;
    }
  }

  a {
    color: black !important;
    &:hover {
      text-decoration: none !important;
    }
  }
`;
const StyledProductGrid = styled(ProductGrid)`
  margin: 0;
  list-tyle: none;
  justify-content: center;
  ${theme('below.lg')} {
    margin: 0;
    padding: 0 5px;
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-track,
    ::-webkit-scrollbar-thumb {
      background-color: transparent;
      display: none;
    }
    ::-webkit-scrollbar {
      display: none;
    }

    > .product-card {
      flex: none;
      width: 48%;
    }
  }
`;

const ProductRow = ({ products, title = null }) => {
  const articleNumbers = Array.isArray(products)
    ? products
    : products.split(',');

  return (
    <React.Fragment>
      {articleNumbers && (
        <Query
          query={productRowQuery}
          variables={{ articleNumbers }}
          errorPolicy="all"
        >
          {({ loading, error, data }) => {
            if (loading) {
              return 'Loading...';
            }
            if (error) {
              return 'Produkter kunde inte hämtas';
            }
            return (
              <>
                <ProductGridWrapper>
                  {title && <h2>{title}</h2>}
                  <StyledProductGrid
                    products={data.products}
                    loading={loading}
                    ProductComponent={ProductCard}
                  />
                </ProductGridWrapper>
              </>
            );
          }}
        </Query>
      )}
    </React.Fragment>
  );
};

export default ProductRow;
