import React from 'react';
import styled from 'react-emotion';
import Image from '@jetshop/ui/Image';
import theme from '@jetshop/ui/utils/theme';

const Wrapper = styled('div')`
  position: relative;
  display: flex;
  align-items: center;
  text-align: left;
  padding-left: 170px;
  min-height: 200px;
  font-size: 2rem;
  font-weight: 400;
  font-style: italic;
  color: #0076b9;
  line-height: 1.4;
`;

const ImageBlock = ({ src, aspect }) => (
  <Image src={src} aspect={aspect} cover={true} />
);
export default ImageBlock;
