import styled from 'react-emotion';
import { Query } from 'react-apollo';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import React, { useContext } from 'react';
import NotFoundPageQuery from './NotFoundPageQuery.gql';

import { Shortcodes } from '@jetshop/flight-shortcodes';
import LongTextImage from './Shortcodes/LongTextImage';
import Row from './Shortcodes/Row';
import Column from './Shortcodes/Column';
import Quote from './Shortcodes/Quote';
import ShortcodeImage from './Shortcodes/Image';
import CTA from './Shortcodes/CTA';
import PageLink from './Shortcodes/PageLink';
import ProductRow from './Shortcodes/ProductRow';
import Video from './Shortcodes/Video';

import MaxWidth from './Layout/MaxWidth';

const ErrorContainer = styled(MaxWidth)`
  padding: 4rem 2rem !important;
`;

const NotFoundPageContent = ({ id }) => (
  <Query query={NotFoundPageQuery} variables={{ id }}>
    {({ loading, error, data }) => {
      if (loading) return null;
      if (error) return `Error!: ${error}`;
      return (
        <>
          {data.category && data.category.content ? (
            <Shortcodes
              content={data.category.content}
              components={{
                LongTextImage: LongTextImage,
                Row: Row,
                Column: Column,
                Quote: Quote,
                Image: ShortcodeImage,
                CTA: CTA,
                PageLink: PageLink,
                ProductRow: ProductRow,
                Video: Video
              }}
            />
          ) : null}
        </>
      );
    }}
  </Query>
);

const NotFoundPage = ({}) => {
  const { selectedChannel } = useContext(ChannelContext);
  return (
    <ErrorContainer>
      {selectedChannel.id === 1 ? (
        <>
          <NotFoundPageContent id={525} />
        </>
      ) : (
        <>
          <NotFoundPageContent id={526} />
        </>
      )}
    </ErrorContainer>
  );
};

export default NotFoundPage;
