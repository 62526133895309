import React from 'react';
import styled from 'react-emotion';
import { Link } from 'react-router-dom';
import { TrendLink } from '../ui/Button';

export const CTAButton = styled(TrendLink)`
  border: 1px solid ${props => props.bordercolor};
  background: ${props => props.bg}!important;
  color: ${props => props.color}!important;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  text-align: center;
  margin: 15px auto;
  padding: 0 40px;
  &:hover {
    text-decoration: none;
  }
`;
export const CTALink = styled('a')`
  border: 1px solid ${props => props.bordercolor};
  background: ${props => props.bg}!important;
  color: ${props => props.color}!important;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  text-align: center;
  margin: 15px auto;
  padding: 0 40px;
  &:hover {
    text-decoration: none;
  }
`;
const ButtonWrapper = styled('div')`
  text-align: center;
`;

const CTA = ({ link, text, bg, color, blank, bordercolor }) => (
  <ButtonWrapper>
    {blank ? (
      <CTALink
        href={link}
        bg={bg}
        color={color}
        bordercolor={bordercolor !== undefined ? bordercolor : bg}
        target="_blank"
        rel="noopener noreferrer"
      >
        {text}
      </CTALink>
    ) : (
      <CTAButton
        to={link}
        bg={bg}
        color={color}
        bordercolor={bordercolor !== undefined ? bordercolor : bg}
      >
        {text}
      </CTAButton>
    )}
  </ButtonWrapper>
);

export default CTA;
