import React from 'react';
import styled from 'react-emotion';

import theme from '@jetshop/ui/utils/theme';

export const ColumnWrapper = styled('div')`
  width: ${props => props.width}%;
  padding: 10px;

  ${theme('below.lg')} {
    padding: 10px 0;
  }

  img {
    max-width: 100%;
  }

  em,
  i {
    font-style: italic;
  }

  a,
  a:visited {
    color: inherit;
  }

  b,
  strong {
    font-weight: 500;
  }

  p {
    line-height: 1.6;
    max-width: 700px;
    margin: 0;
    padding: 5px 30px;
    text-align: left;
    font-size: 14px;
  }

  ul {
    margin-bottom: 1rem;
    margin-left: 1rem;
    li {
      list-style-type: disc;
      line-height: 1.6;
    }
  }
  div {
    line-height: 1.6;
  }
  u {
    display: block;
    cursor: pointer;
  }

  h1,
  h2,
  h3,
  h4 {
    text-transform: uppercase;
    max-width: 700px;
    margin: 2rem auto 0.2rem auto;
    text-align: left;
    padding-left: 30px;
    padding-right: 30px;
  }

  h1 {
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-align: center;
  }
  h2 {
    line-height: 1.6;
    max-width: 700px;
    margin: 2rem auto 0.2rem auto;
    font-size: 1.5rem;
  }
  h3 {
    line-height: 1.2;
  }
  h4 {
    font-weight: bold;
    font-size: 1rem;
    color: ${theme('colors.primary')};
    padding: 0 30px;
  }

  p,
  h1,
  h2,
  h3,
  h4 {
    ${theme('below.lg')} {
      padding: 0;
    }
  }

  hr {
    max-width: 700px;
    margin: 2rem auto;
    display: block;
  }

  ${theme('below.lg')} {
    width: 100%;
  }

  img {
    display: block;
  }
`;

export const RowWrapper = styled('section')`
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: flex-start;
  align-items: center;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: 30px;

  width: auto;
  min-width: 100%;

  ${theme('below.lg')} {
    flex-wrap: wrap;
    margin: 0;
  }

  &.maxWidth {
    margin: auto;
    width: 100%;
    min-width: 0;
    max-width: ${props => props.maxWidth};
  }
`;

export const Column = ({ children, width }) => (
  <ColumnWrapper width={width}>{children}</ColumnWrapper>
);

const Row = ({ children, maxWidth, oneThird }) => (
  <React.Fragment>
    {children.props ? (
      <RowWrapper className={maxWidth ? 'maxWidth' : null} maxWidth={maxWidth}>
        <Column children={children} width={oneThird ? 100 / 3 : 100 / 2} />
      </RowWrapper>
    ) : null}
    {children.length > 0 ? (
      <RowWrapper className={maxWidth ? 'maxWidth' : null} maxWidth={maxWidth}>
        {children.map((child, index) => (
          <Column children={child} key={index} width={100 / children.length} />
        ))}
      </RowWrapper>
    ) : null}
  </React.Fragment>
);

export default Row;
